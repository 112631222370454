<template>
	<button
		type="button"
		class="button"
		:title="title"
		@click="click"
	>
		<BaseIcon :src="icon" />
		<span>
			<slot />
		</span>
	</button>
</template>

<script>
export default {
	props: {
		icon: {
			type: String,
			default: ''
		},
		title: {
			type: String,
			default: ''
		},
		click: {
			type: Function,
			required: true
		}
	}
};
</script>

<style lang="scss">
	.button {
		@include button();
		margin: 0 0 1rem;

		> svg {
			display: inline-block;
			width: 1.5em;
			max-width: none;
			height: 1.5em;
			vertical-align: middle;

			&:first-child {
				margin-right: 0.25rem;
			}
		}

		&-primary {
			@include button-primary();
		}

		&-secondary {
			@include button-secondary();
		}
	}
</style>
